/* Існуючі стилі */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #222;
  color: #fff;
}

.meme-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* width: 70vw; */
  height: 70vh;
  border-radius: 10px;
  overflow: hidden;
}

.meme-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.meme-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  pointer-events: none;
}

.meme-text.top {
  top: 10px;
}

.meme-text.bottom {
  bottom: 10px;
}

.grid-container {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  /* gap: 10px; */
}

.item {
  flex: 1 1 200px;
}

.preview-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  max-width: 400px;
  background-color: #282c34;
  border: 2px solid #333;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  color: white;
}

@media (max-width: 768px) {
  .grid-container {
    flex-direction: column !important;
    flex-wrap: unset !important;
  }

  .grid-image {
    max-width: 100% !important;
  }

  .grid-redactor {
    max-width: 100% !important;
  }

  .meme-container {
    width: 100%;
    height: auto;
  }

  .editor-controls {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .preview-container {
    width: 90%;
    padding: 8px;
  }
}

.upload-modal {
  width: 500px;
  padding: 32px;
  background-color: #333;
  margin: auto;
  margin-top: 10%;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-modal-title {
  margin-bottom: 16px;
  color: #fff;
}

.choose-file-btn {
  margin-bottom: 16px;
  background-color: #0057b7;
}

.choose-file-btn:hover {
  background-color: #0072e5;
}

.drop-zone {
  border: 2px dashed #0057b7;
  border-radius: 8px;
  padding: 24px;
  margin-top: 16px;
  background-color: #444;
  color: #bbb;
  text-align: center;
  width: 100%;
  max-width: 450px;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
